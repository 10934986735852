<template>
  <v-container class="get-spot-loads">
    <v-data-table
      dense
      :headers="loadHeaders"
      :items="loads"
      item-key="item.loadId"
      :loading="loading"
      @click:row="MoveToDetail"
    >
      <template v-slot:item.origin="{ item }">
        <p>
          <span class="font-weight-bold"
            >{{ item.origin.originCity }}, {{ item.origin.originState }}</span
          >
          <span class="subtitle">
            <date-formatter :date="item.origin.pickupDate" />
          </span>
        </p>
      </template>

      <template v-slot:item.destination="{ item }">
        <p>
          <span class="font-weight-bold"
            >{{ item.destination.destCity }},
            {{ item.destination.destState }}</span
          >
          <span class="subtitle">
            <date-formatter :date="item.destination.deliveryDate" />
          </span>
        </p>
      </template>
      <template v-slot:item.dateCreated="{ item }">
        <date-formatter :date="item.dateCreated" />
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import api from "@/api/api"
import DateFormatter from "@/views/pages/components/DateFormatter"
import common from "@/utils/common"
const snackbarType = common.data.snackbarType
export default {
  name: "GetSpotLoads",
  components: {
    DateFormatter,
  },
  props: {
    endpoint: {
      type: String,
      default: null,
    },
    shipperCity: {
      type: String,
      default: "",
    },
    shipperState: {
      type: String,
      default: "",
    },
    consigneeCity: {
      type: String,
      default: "",
    },
    consigneeState: {
      type: String,
      default: "",
    },
    equipmentType: {
      type: String,
      default: "",
    },
    pickupDate: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    loading: false,
    loads: [],
    loadHeaders: [
      { text: "Load Number", value: "loadId" },
      {
        text: "Load Status",
        value: "loadStatus",
        sortable: false,
        filterable: false,
      },
      { text: "Customer Ref Id", value: "customerRefId" },
      { text: "Customer", value: "customer" },
      { text: "Equipment", value: "equipment" },
      { text: "Origin", value: "origin" },
      { text: "Destination", value: "destination" },
      { text: "Commodity", value: "commodity" },
      { text: "Rate", value: "rate", sortable: false, filterable: false },
      { text: "Date Created", value: "dateCreated" },
    ],
  }),
  mounted() {
    this.getLaneLookup({
      city: this.shipperCity,
      state: this.shipperState,
      equipmentType: this.equipmentType,
    })
  },
  methods: {
    checkForCustomer(customer) {
      if (customer) {
        return customer.name
      } else {
        return null
      }
    },
    getLaneLookup(city, state, equipmentType) {
      this.loading = true
      api
        .get(`${process.env.VUE_APP_BASE_URL}${this.endpoint}`, {
          params: {
            shipper__city: this.shipperCity,
            shipper__state: this.shipperState,
            consignee__city: this.consigneeCity,
            consignee__state: this.consigneeState,
            equipment_type: this.equipmentType,
          },
        })
        .then((response) => {
          for (const item of response.data) {
            const load = {
              loadId: item.id,
              loadStatus: item.load_status,
              customerRefId: item.customer_reference_id,
              customer: this.checkForCustomer(item.customer_company),
              equipment: item.equipment_type,
              origin: {
                pickupDate: item.pickup_date,
                originCity: item.shipper.city,
                originState: item.shipper.state,
              },
              destination: {
                deliveryDate: item.delivery_date,
                destCity: item.consignee.city,
                destState: item.consignee.state,
              },
              commodity: item.description,
              rate: item.customer_price,
              dateCreated: item.date_created,
            }
            this.loads.push(load)
          }
          this.loading = false
        })
        .catch((e) => {
          let payload
          if (e.response) {
            const snackbarText = e.response.data.detail
            payload = {
              status: true,
              text: snackbarText,
              type: snackbarType.error,
            }
          } else {
            const snackbarText = "Whoops, something went wrong"
            payload = {
              status: true,
              text: snackbarText,
              type: snackbarType.error,
            }
          }
          this.$store.dispatch("main/SET_SNACKBAR", payload)
          this.loading = false
        })
    },
    MoveToDetail(value) {
      this.$router.push({
        name: "Load Detail",
        params: { id: value.loadId },
      })
    },
  },
}
</script>
