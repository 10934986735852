<template>
  <v-container id="accepted-tenders">
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="4">
            <v-select
              v-model="equipmentFilter"
              :items="equipmentList"
              label="Equipment"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="originFilter.city"
              type="text"
              label="Origin City"
            />
            <v-text-field
              v-model="destinationFilter.city"
              type="text"
              label="Destination City"
            />
          </v-col>
          <v-col cols="2">
            <v-text-field
              v-model="originFilter.state"
              type="text"
              label="Origin State"
            />

            <v-spacer />
            <v-text-field
              v-model="destinationFilter.state"
              type="text"
              label="Destination State"
            />
          </v-col>
          <v-col cols="2">
            <v-row>
              <v-spacer />
              <v-btn small @click="resetSearch()"> Reset Search </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="laneHeaders"
        :items="indexedLanes"
        :expanded.sync="expanded"
        item-key="id"
        show-expand
        :loading="loading"
        height="400"
        fixed-header
        single-expand
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      >
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <get-loads
              :shipper-city="item.shipperCity"
              :shipper-state="item.shipperState"
              :consignee-city="item.consigneeCity"
              :consignee-state="item.consigneeState"
              :equipment-type="item.equipmentType"
              :pickup-date="item.pickupDate"
              endpoint="/loads/accepted-tender-lookup/"
            />
          </td>
        </template>
        <template v-slot:item.origin="{ item }">
          <p>
            <span class="font-weight-bold"
              >{{ item.origin.city }}, {{ item.origin.state }}</span
            >
            <br />
            <span class="subtitle">{{ item.origin.src_date_formatted }}</span>
          </p>
        </template>
      </v-data-table>
    </v-card>
    <v-card>
      <v-card-title />
      <carrier-matches />
    </v-card>
  </v-container>
</template>

<script>
import api from "@/api/api"
import GetLoads from "@/views/pages/components/GetLoads"
import CarrierMatches from "@/views/pages/carrier-matching/CarrierMatches"
import common from "@/utils/common"
const snackbarType = common.data.snackbarType
export default {
  name: "Spotboard",
  components: {
    GetLoads,
    CarrierMatches,
  },

  data() {
    return {
      sortBy: "matchCount",
      sortDesc: true,
      equipmentList: [],
      equipmentFilter: "",
      expanded: [],
      loading: false,
      originFilter: {
        city: "",
        state: "",
      },
      destinationFilter: {
        city: "",
        state: "",
      },
      laneHeaders: [
        {
          text: "Origin City",
          value: "shipperCity",
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this.originFilter.city.toLowerCase())
          },
        },
        {
          text: "Origin State",
          value: "shipperState",
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this.originFilter.state.toLowerCase())
          },
        },
        {
          text: "Consignee City",
          value: "consigneeCity",
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this.destinationFilter.city.toLowerCase())
          },
        },
        {
          text: "Consignee State",
          value: "consigneeState",
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this.destinationFilter.state.toLowerCase())
          },
        },
        {
          text: "Equipment Type",
          value: "equipmentType",
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this.equipmentFilter.toLowerCase())
          },
        },
        { text: "Load Count", value: "loadCount" },
        { text: "Matches", value: "matchCount" },
      ],
      lanes: [],
    }
  },
  computed: {
    indexedLanes() {
      return this.lanes.map((item, index) => ({
        id: index,
        ...item,
      }))
    },
    equipmentType() {
      return this.equipmentList.filter((x, i, a) => a.indexOf(x) === i)
    },
  },
  watch: {
    expanded: function () {
      if (this.expanded.length > 0) {
        const expandedItem = this.expanded[0]
        this.$store.dispatch("spotboard/SET_SHIPPER", expandedItem.shipperId)
        this.$store.dispatch(
          "spotboard/SET_CONSIGNEE",
          expandedItem.consigneeId
        )
      } else {
        this.$store.dispatch("spotboard/EMPTY_CARRIER_LIST")
        this.$store.dispatch("spotboard/SET_SHIPPER", null)
        this.$store.dispatch("spotboard/SET_CONSIGNEE", null)
      }
    },
  },
  mounted() {
    this.getLanes()
  },
  methods: {
    resetSearch() {
      this.equipmentFilter = ""
      this.originFilter.city = ""
      this.originFilter.state = ""
      this.destinationFilter.city = ""
      this.destinationFilter.state = ""
    },
    getLanes() {
      this.loading = true
      api
        .get(`${process.env.VUE_APP_BASE_URL}/loads/api/accepted-tenders/`)
        .then((response) => {
          this.loading = false
          for (const item of response.data.grouped_lanes) {
            const lane = {
              pickupDate: item.pickup_date,
              deliveryDate: item.delivery_date,
              shipperCity: item.shipper.city,
              shipperState: item.shipper.state,
              consigneeCity: item.consignee.city,
              consigneeState: item.consignee.state,
              equipmentType: item.equipment_type,
              loadCount: item.load_count,
              shipperId: item.shipper.id,
              consigneeId: item.consignee.id,
              matchCount: item.match_count,
            }
            this.equipmentList.push(item.equipment_type)
            this.lanes.push(lane)
          }
        })
        .catch((e) => {
          let payload
          if (e.response) {
            const snackbarText = e.response.data.detail
            payload = {
              status: true,
              text: snackbarText,
              type: snackbarType.error,
            }
          } else {
            const snackbarText = "Whoops, something went wrong"
            payload = {
              status: true,
              text: snackbarText,
              type: snackbarType.error,
            }
          }
          this.$store.commit("main/SET_SNACKBAR", payload)
          this.loading = false
        })
    },
  },
}
</script>
