<template>
  <div class="date-format">
    {{ formattedDate }}
  </div>
</template>

<script>
export default {
  name: "DateFormatter",
  props: {
    date: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    formattedDate: null,
  }),
  created() {
    this.formatDate(this.date)
  },
  methods: {
    formatDate(date) {
      const dateObject = new Date(date)
      const formattedDate = `${
        dateObject.getUTCMonth() + 1
      }/${dateObject.getUTCDate()}/${dateObject
        .getFullYear()
        .toString()
        .substr(-2)}`
      this.formattedDate = formattedDate
    },
  },
}
</script>
