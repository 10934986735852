<template>
  <div id="carrier-matches">
    <v-data-table
      :headers="headers"
      :items="carriers"
      :loading="loading"
      dense
      fixed-header
      @click:row="openCarrierMatches"
    >
      <template v-slot:header.averageRate>
        <span>Average Rate </span>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon x-small v-on="on"> mdi-information-outline </v-icon>
          </template>
          <span>Average assigned rate or carrier bid.</span>
        </v-tooltip>
      </template>
      <template v-slot:item.matchType="{ item }">
        <v-icon v-if="item.matchType === 1"> mdi-history </v-icon>
        <v-icon v-if="item.matchType === 2"> mdi-account </v-icon>
      </template>
      <template v-slot:item.averageRate="{ item }">
        <template v-if="item.averageRate !== null">
          ${{ item.averageRate }}
        </template>
        <template v-else-if="item.averageBid !== null">
          ${{ item.averageBid }}
        </template>
        <template v-else> -- </template>
      </template>
    </v-data-table>
    <!-- <matches-detail /> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import api from "@/api/api"
import common from "@/utils/common"
const snackbarType = common.data.snackbarType
export default {
  name: "CarrierMatches",
  props: {
    shipperId: {
      type: Number,
      default: 0,
    },
    consigneeId: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    loading: false,
    headers: [
      { text: "Match Type", value: "matchType", align: "center" },
      { text: "DOT Number", value: "dotNumber", align: "center" },
      { text: "Carrier Name", value: "carrierName", align: "center" },
      { text: "Primary Phone", value: "carrierPhone", align: "center" },
      { text: "Primary Email", value: "primaryEmail", align: "center" },
      { text: "Matches", value: "matchCount", align: "center" },
      { text: "Average Rate", value: "averageRate", align: "center" },
    ],
  }),
  computed: {
    shipper() {
      return this.$store.state.spotboard.shipper
    },
    consignee() {
      return this.$store.state.spotboard.consignee
    },
    ...mapGetters("spotboard", {
      carriers: "carrierList",
      matchDetailDialog: "matchDetailDialog",
    }),
  },
  watch: {
    shipper: function () {
      if (this.shipper) {
        this.getHistoricalCarriers(this.shipper, this.consignee)
      }
    },
  },
  methods: {
    openCarrierMatches() {
      if (!this.matchDetailDialog) {
        this.$store.commit("spotboard/setMatchDetailDialog", true)
      } else {
        this.$store.commit("spotboard/setMatchDetailDialog", false)
        this.$store.commit("spotboard/setMatchDetailDialog", true)
      }
    },
    getHistoricalCarriers(shipper, consignee) {
      this.loading = true
      const carriers = []
      api
        .get(
          `${process.env.VUE_APP_BASE_URL}/carrier-matching/api/carrier-lane-matches/?shipper=${shipper}&consignee=${consignee}`
        )
        .then((response) => {
          for (const item of response.data) {
            const carrier = {
              dotNumber: item.carrier.dot_number,
              carrierName: item.carrier.name,
              carrierPhone: item.carrier.phone,
              primaryEmail: item.carrier.email,
              matchCount: item.load_count,
              matchType: item.match_type,
              averageRate: item.average_rate,
              averageBid: item.carrier_bid_average,
            }
            carriers.push(carrier)
          }
          this.$store.dispatch("spotboard/SET_CARRIER_LIST", carriers)
          this.loading = false
        })
        .catch((e) => {
          let payload
          if (e.response) {
            const snackbarText = e.response.data.detail
            payload = {
              status: true,
              text: snackbarText,
              type: snackbarType.error,
            }
          } else {
            const snackbarText = "Whoops, something went wrong"
            payload = {
              status: true,
              text: snackbarText,
              type: snackbarType.error,
            }
          }
          this.$store.commit("main/SET_SNACKBAR", payload)
          this.loading = false
        })
    },
  },
}
</script>
